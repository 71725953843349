@import '../../../../scss/imports.scss';

.hatchCanvas {
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: #00000063;
  border-radius: 30px;
}
.step {
  color: $main-color;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0.5rem 0.5rem;
  text-shadow: 2px 2px 2px #000;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../../assets/images/misc/desertscene.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}
