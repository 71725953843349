@import '../../../scss/imports.scss';
.body {
  overflow: hidden;
  position: relative;
}
.topNavContainer {
  width: 100%;
  background-color: #00000060;
  display: flex;
  align-items: center;
  // box-shadow: 0px -3px 10px 5px #0e072b;
  z-index: 999;
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  &.overlap {
    position: absolute;
  }
}
.navLi {
  list-style-type: none;
  .navItem {
    vertical-align: middle;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: $text-main-color;
    font-weight: bold;
    margin: 0.75rem 0.75rem;
    a {
      text-decoration: none;
      color: $text-main-color;
    }
    &.active {
      color: #a0a1ff;
      text-decoration: underline;
    }
    &:hover {
      color: #a0a1ff;
    }

    &.logo-nav-item {
      margin: 0.25rem 0;
      background-image: url('../../../assets/images/logoLW.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 175px;
      height: 70px;
      display: inline-block;

      @include media-breakpoint-down(sm) {
        background-size: contain;
        width: 130px;
        height: 50px;
      }
    }
  }
  &.topMenuLi {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &.sideMenuLi {
    min-width: 300px;
    .navItem {
      display: block;
      padding: 0px;
      font-size: 3.5rem;
      margin: 0 3rem 2rem 0;
      // transition: 0.3s;
      // border-bottom: 1px solid white;
      @include media-breakpoint-down(sm) {
        margin: 0 0 0.75rem 0;
        font-size: 2.5rem;
      }
      &.subItem {
        font-size: inherit;
        margin: 1rem auto;
        text-transform: initial;
      }
    }
  }
}
.openseaSvg {
  height: 32px;
  width: 32px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.logo-navLi {
  z-index: 1000;
}
.side-menu {
  display: flex;
  align-items: center;

  // @include media-breakpoint-down(sm) {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(26, 28, 29, 0.95);
  transition: left 0.5s, visibility 0.5s;
  z-index: 999;
  display: block;
  padding-top: 100px;
  visibility: hidden;

  &.active {
    left: 0;
    visibility: visible;
  }
  // }
}
.side-menu-container {
  width: 100%;
  display: flex;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding-left: 2rem;
  }
}
.whitepaper {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  .whitepaperText {
    color: $main-color;
    border-top: 1px solid;
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  img {
    margin-top: 20px;
    width: 250px;
    box-shadow: 0 0 5px 1px white;
    border: 5px solid #a0a1ff;
    animation: pulse 1s linear infinite;
    transition: 0.1s;
    &:hover {
      border: none;
      box-shadow: 0 0 7px 3px white;
      animation: none;
    }
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.hamburger {
  position: relative;
  cursor: pointer;
  z-index: 1000;
  margin-left: auto;
  width: 50px;
  height: 70px;
  margin-right: 20px;
  user-select: none;

  // @include media-breakpoint-up(md) {
  //   display: none;
  // }
  span {
    user-select: none;
    display: block;
    position: absolute;
    height: 6px;
    width: 50px;
    background: #e2e2e2;
    border-radius: 9px;
    right: 0;
    margin-top: 15px;
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, right 0.5s ease-in-out, top 0.5s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 15px;
    }
    &:nth-child(3) {
      top: 30px;
    }
  }
}
.hamburger.open span:nth-child(1) {
  top: 15px;
  transform: rotate(135deg);
}
.hamburger.open span:nth-child(2) {
  opacity: 0;
  right: 50px;
}
.hamburger.open span:nth-child(3) {
  top: 15px;
  transform: rotate(-135deg);
}

.socialsContainer {
  color: rgb(160, 161, 255);
  display: flex;
  flex-direction: column;
  display: block;
  padding: 0px;
  font-size: 1.5rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
  a {
    color: rgb(160, 161, 255);
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    > svg {
      margin-right: 1rem;
    }
  }
}
