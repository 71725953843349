$main-color: #fff;
$main-purple: #895cfe;
$secondary-color: #a0a1ff;

$main-background: #18191a;
$secondary-background: #202225;
$heading-background: #030304;
$random-blue-background: #5daefe;

$active-bright: #00fca8;
$hover: #00fca8d5;
$active: #a8eb7c;

$text-color: #8a939b;
$text-main-color: #e2e2e2;
$text-error: $main-purple;
