@import './scss/imports.scss';

@font-face {
  font-family: rrcMainFont;
  src: url('https://rowdyrooster.io/cdn/fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('https://rowdyrooster.io/cdn/fonts/poppins-regular-webfont.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: rrc;
  src: url('https://rowdyrooster.io/cdn/fonts/deadstock-webfont.woff2') format('woff2'),
    url('https://rowdyrooster.io/cdn/fonts/deadstock-webfont.woff') format('woff');
  font-display: swap;
}

* {
  box-sizing: border-box;
}
html {
  -webkit-text-size-adjust: 100%;
}
body {
  padding: 0;
  margin: 0px;
  background-color: $main-background;
  font-family: rrcMainFont, Helvetica, Arial, sans-serif;
}
input {
  font-family: rrcMainFont, Helvetica, Arial, sans-serif;
}
.name-font {
  font-family: rrc, Helvetica, Arial, sans-serif;
}
a {
  text-decoration: none;
}
img {
  display: block;
}

.scrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.scrollBar::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: #fff;
}
.scrollBar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}

.header {
  width: 100%;
  font-size: 40px;
  text-align: center;
  color: #fff;
  padding: 20px 0;
}
@media only screen and (max-width: 1024px) {
  .header {
    font-size: 25px;
  }
}
