@import '../../../scss/imports.scss';

.footer {
  padding: 1rem 1rem 5rem;
  background-color: $secondary-background;
  border-top: 3px solid #858aa8;
  a,
  a:active {
    color: inherit;
    text-decoration: none;
  }
  a:focus,
  a:hover {
    color: inherit;
    text-decoration: underline;
  }
}
.address-container {
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    margin-left: 2rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
  div {
    color: #fff;
    margin-bottom: 0.5rem;
  }
  a {
    color: $secondary-color;
  }
}
.footer-link-container {
  display: flex;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
.links {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: $secondary-color;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-left: 2rem;
    margin-bottom: 0;
  }
  > a {
    margin: 0.25rem 0;
  }
}
.socialsContainer {
  color: rgb(160, 161, 255);
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    // margin-left: auto;
    width: 33%;
  }
  a {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    > svg {
      margin-right: 1rem;
    }
  }
}
.title {
  font-size: 1.75rem;
  border-bottom: 3px solid #858aa8;
  margin-right: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.copyright {
  padding: 1rem 0 0;
  color: #fff;
}
.openseaSvg {
  height: 32px;
  width: 32px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
