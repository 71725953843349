@import '../../../scss/imports.scss';

.popup {
  position: fixed;
  background: #000000bf;
  z-index: 1100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.popupBody {
  position: absolute;
  border: none;
  background: $main-background;
  top: 50%;
  left: 0;
  right: 0;
  bottom: auto;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  margin-left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1100;
  max-width: 1000px;
  min-width: 50vw;
  min-height: 350px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 90vw;
  }
}
.body {
  overflow: hidden;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 1rem;
  color: $secondary-color;
  background-color: $secondary-background;
  h2 {
    margin: 1.25rem 0;
    @include media-breakpoint-down(xs) {
      font-size: 1.25em;
    }
  }
}
.bodyContainer {
  overflow: auto;
}
.closeIcon {
  cursor: pointer;
  padding: 1rem;
}
