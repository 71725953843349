@import '../../../../scss/imports.scss';

.eggCanvas {
  max-width: 100%;
  margin-bottom: 1rem;
}
.step {
  color: $text-color;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0.5rem 0.5rem;
  text-shadow: 2px 2px 2px #000;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
