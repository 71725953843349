@import '../../../../scss/imports.scss';

.formField {
  color: $text-main-color;
  margin-bottom: 0.5rem;
  // @include media-breakpoint-down(sm) {
  // height: 700px;
  // }
  .inputField {
    display: flex;
    flex-direction: column;
    .input {
      margin-bottom: 0.25rem;
      width: 100%;
      outline: 0;
      margin: 0;
      padding: 0.5rem 0.5rem;
      background: $secondary-background;
      border: unset;
      color: $text-main-color;

      &:disabled {
        background: $main-background;
        color: $text-color;
      }
    }
    .label {
      margin-bottom: 0.25rem;
    }
  }
}
.error {
  color: $text-error;
}
