@import '../../../scss/imports.scss';

.btn {
  cursor: pointer;
  text-transform: uppercase;
  padding: 20px;
  margin: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background: rgba($main-background, 0.75);
  border: 1px solid #000f;
  box-shadow: 0px 0px 2px 2px #454647;
  outline: 1px solid;
  transition: outline-offset 1250ms cubic-bezier(0.19, 1, 0.22, 1), outline-color 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  outline-color: #ffffff80;
  text-decoration: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  &.disabled {
    color: #333;
    cursor: unset;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px;
    margin: 10px;
    font-size: 14px;
    transition: 0s;
  }
  &:focus {
    border: 1px solid;
  }
}

.btn:not(.disabled):hover {
  border: 1px solid;
  box-shadow: inset 0 0 30px #ffffff80, 0 0 60px #ffffff33;
  outline-offset: 25px;
  outline-color: rgba(255, 255, 255, 0);
  text-decoration: none;
}

.icon {
  margin-right: 0.5rem;
  display: block;
  img {
    vertical-align: middle;
  }
}
