@import '../../../../scss/imports.scss';

.form {
  width: 100%;
  margin-bottom: 1rem;
}

.step {
  color: $text-color;
  font-weight: bold;
  text-align: center;
  margin: 1rem 0.5rem 0.5rem;
  text-shadow: 2px 2px 2px #000;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
}
.rules-container {
  color: $text-color;
  margin-bottom: 1rem;

  .rules-title {
    color: $text-main-color;
    font-size: 1.25rem;
  }
  .rules-ul {
    margin: 0;
  }
  .rules-warning {
    color: $hover;
  }
}
